import "./App.css";
import About from "./components/About";

import Footer from "./components/Footer";

import Header from "./components/Header";
import Products from "./components/Products";

function App() {
  return (
    <div>
      <Header />
      <About />
      <Products />{" "}
      {/*needs to be call with map function and prior to it add some data */}
      <Footer />
    </div>
  );
}

export default App;
