import React from "react";

const Footer = () => {
  return (
    <div class="container d-flex flex-column min-vh-70">
      <footer class="py-3 my-4 mt-auto">
        <p class="text-center text-muted">&copy; 2021 Astron Poly Tech</p>
      </footer>
    </div>
  );
};

export default Footer;
