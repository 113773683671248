import React from "react";
import "./Products.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ConduitingImage from "../Images/Conduting Fitting.jpg";
import conduitPipeImage from "../Images/ConduitPipe.jfif";
import conduitBendImage from "../Images/pvc-long-bend-pipe.jpg";
const Products = () => {
  return (
    <div className="container" class="container">
      <h1 className="ourProducts">Our Products</h1>
      <ul className="ourProduct">
        <li>PVC Electrical Pipe & Fittings.</li>
        <li>Garden Hose Piping.</li>
      </ul>

      <div class="row">
        <div class="col">
          <h1>Electrical Conduiting System </h1>
          <hr className="hrLineOrange" />
          <img
            className="pipeImage2"
            src={conduitPipeImage}
            alt="conducting_pipe_image"
          />
          <div className="description_of_product">
            <div className="Feature">
              <h1>Features</h1>
              <ul>
                <li>String and best.</li>
                <li>Flawless Performance</li>
                <li>Works Best</li>
                <li>Reliable and Durable</li>
              </ul>
            </div>
            <div className="Specification">
              <h1>Specification</h1>
              <ul>
                <li>20mm to 50mm outer diameter</li>
                <li>Light, Medium and Heavy</li>
                <li>IS 9537 :1983 (Part-3) for PVC conduit pipe</li>
                <li>IS:3419 for PVC conduit fittings</li>
                <li>Available in 3 mtr. lenght</li>
              </ul>
            </div>
            <div className="Application">
              <h1>Application</h1>
              <ul>
                <li>
                  Manufactured from pure poly Vinyl Chloride Resin with minimum
                  possible fillers in order to give the desired strength and
                  flexibility{" "}
                </li>
                <li>
                  PVC Conduit pipes is simply a tube that is used to enclose,
                  lay, and protect or conceal electrical wirings in a building
                  for a safer fit.
                </li>
                <li>
                  These have fire-retardant and low-smoke properties so that it
                  doesn’t catch fire, and causes no harm to the infrastructure
                </li>
              </ul>
            </div>
          </div>
          <table class="table table-light">
            <thead>
              <tr class="table-dark">
                <th scope="col">Size (mm)</th>
                <th scope="col">Standard Packing/bundle (Mtrs.)</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr class="table-light">
                <td>20</td>
                <td>120</td>
              </tr>
              <tr class="table-dark">
                <td>25</td>
                <td>75</td>
              </tr>
              <tr class="table-light">
                <td>32</td>
                <td>45</td>
              </tr>
              <tr class="table-dark">
                <td>40</td>
                <td>30</td>
              </tr>
              <tr class="table-light">
                <td>50</td>
                <td>24</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col">
          <h1>PVC Conduiting Fitting </h1>
          <hr className="hrLineOrange" />
          <div class="row">
            <h4>PVC Conduit Long Bend</h4>
            <img
              className="fittingImageBend"
              src={conduitBendImage}
              alt="fitting-Bend"
            />
            <table class="table table-light">
              <thead>
                <tr class="table-dark">
                  <th scope="col">Size (mm)</th>
                  <th scope="col">Standard packaging (Pcs)</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr class="table-light">
                  <td>20</td>
                  <td>63</td>
                </tr>
                <tr class="table-dark">
                  <td>25</td>
                  <td>24</td>
                </tr>
                <tr class="table-light">
                  <td>32</td>
                  <td>12</td>
                </tr>
                <tr class="table-dark">
                  <td>40</td>
                  <td>12</td>
                </tr>
                <tr class="table-light">
                  <td>50</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <h4>PVC Conduit Coupler</h4>
            <img
              className="fittingImageCoupler"
              src={ConduitingImage}
              alt="pipe_image"
            />
            <table class="table table-light">
              <thead>
                <tr class="table-dark">
                  <th scope="col">Size (mm)</th>
                  <th scope="col">Standard packaging (Pcs)</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr class="table-light">
                  <td>20</td>
                  <td>-</td>
                </tr>
                <tr class="table-dark">
                  <td>25</td>
                  <td>60</td>
                </tr>
                <tr class="table-light">
                  <td>32</td>
                  <td>36</td>
                </tr>
                <tr class="table-dark">
                  <td>40</td>
                  <td>24</td>
                </tr>
                <tr class="table-light">
                  <td>50</td>
                  <td>24</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h1>Garden Piping Solution </h1>
          <hr className="hrLineOrange" />
          <img
            src="https://4.imimg.com/data4/AP/AM/MY-4911254/pvc-zebra-colour-transparent-pipe-500x500.jpg"
            alt="pipe_image"
          />
          <div className="availableColor">
            <lable style={{ fontSize: "2rem", fontWeight: "600" }}>
              Available in multi-color
            </lable>
            <div className="multi_color_divs">
              <div className="blue"></div>
              <div className="green">green</div>
              <div className="yellow">yellow</div>
              <div className="orange">orange</div>
              <div className="pink">pink</div>
              <div className="white">white</div>
            </div>
          </div>
          <div className="description_of_product">
            <div className="Feature">
              <h1>Features</h1>
              <ul>
                <li>Long working pressure</li>
                <li>Light Weight</li>
                <li>Easy to handle, strong & economical</li>
                <li>Long life & length</li>
                <li>Flexible & Easy Storage</li>
              </ul>
            </div>
            <div className="Specification">
              <h1>Specification</h1>
              <ul>
                <li>12mm to 38 mm inside diameters</li>
                <li>2mm to 42mm in wall thickness</li>
                <li>Easy to handle, strong & economical</li>
                <li>Available Length in 15 to 30 meter.</li>
                <li>Available in Single Layer & Double Layer</li>
                <li>Zebra, Ripped, Transparent & opaque</li>
              </ul>
            </div>
            <div className="Application">
              <h1>Application</h1>
              <ul>
                <li>
                  Gardening, water transportation and other daily application
                </li>
                <li>
                  Spraying water, domestic pesticides and other liquid agent.
                </li>
                <li>Leveling floor on construction sites. </li>
                <li>Automobiles industries and laboratories</li>
              </ul>
            </div>
          </div>
          <table class="table table-light">
            <thead>
              <tr class="table-dark">
                <th scope="col">Size(mm)</th>
                <th scope="col">Wall Thickness</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr class="table-light">
                <td>12</td>
                <td>2.2</td>
              </tr>
              <tr class="table-dark">
                <td>19</td>
                <td>2.6</td>
              </tr>
              <tr class="table-light">
                <td>25</td>
                <td>2.9</td>
              </tr>
              <tr class="table-dark">
                <td>32</td>
                <td>3.2</td>
              </tr>
              <tr class="table-light">
                <td>38</td>
                <td>4.2</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Products;
