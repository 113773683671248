import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const About = () => {
  return (
    <section class="py-5 text-center container" style={{ marginTop: "12rem" }}>
      <div class="row py-lg-5">
        <div class="col-lg-6 col-md-8 mx-auto">
          <h1 class="fw-light">About Us</h1>
          <p class="lead text-muted">
            APT ASTRON is the innovative leader that combines advanced
            technology with superior customer service to create the industry's
            most sophisticated and diverse products in PVC. Founded in 2021, we
            began our journey by producing PVC Garden Hose Pipe. Later on we
            added PVC Electric Conduit Pipe & fittings to the list of products.
            We manufacture internationally standardized PVC Electric Conduit
            Pipe & Fittings. APT ASTRON has a legacy and goodwill of serving
            world class products for over 2 Years, with ISI certification on all
            our products.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
